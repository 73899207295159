export const navigationItems = {
  SUPERUSER: [
    {
      menu: [
        {
          name: 'Dashboard',
          to: '/dashboard',
          text: 'dashboard',
          submenus: []
        },
        {
          name: 'Dealers',
          to: '/dealers',
          text: 'dealers',
          submenus: []
        },
        {
          name: 'Clients',
          to: '/clients',
          text: 'clients',
          submenus: []
        },
        {
          name: 'References',
          to: '/references',
          text: 'references',
          submenus: []
        },
        {
          name: 'Records',
          to: '/records',
          text: 'records',
          submenus: []
        },
        {
          name: 'Settings',
          to: '/settings',
          text: 'settings',
          submenus: []
        }
      ],
      userMenu: [
        {
          name: 'Profile',
          to: '/profile',
          text: 'profile',
          submenus: []
        },
        {
          name: 'Account',
          to: '/account',
          text: 'account',
          submenus: []
        },
        {
          name: 'Settings',
          to: '/settings',
          text: 'settings',
          submenus: []
        }
      ]
    }
  ],
  DEALER_ADMIN: [
    {
      menu: [
        {
          name: 'Dashboard',
          to: '/dashboard',
          text: 'dashboard',
          submenus: []
        },
        {
          name: 'References',
          to: '/references',
          text: 'references',
          submenus: [
            {
              name: 'Customers',
              to: '/references/customers',
              text: 'customers'
            },
            {
              name: 'Destinations',
              to: '/references/destinations',
              text: 'destinations'
            },
            {
              name: 'Drivers',
              to: '/references/drivers',
              text: 'drivers'
            },
            {
              name: 'Locations',
              to: '/references/locations',
              text: 'locations'
            },
            {
              name: 'Notes',
              to: '/references/notes',
              text: 'notes'
            },
            {
              name: 'Products',
              to: '/references/products',
              text: 'products'
            },
            {
              name: 'Trucks',
              to: '/references/trucks',
              text: 'trucks'
            }
          ]
        },
        {
          name: 'Records',
          to: '/records',
          text: 'records',
          submenus: []
        }
      ],
      userMenu: [
        {
          name: 'Profile',
          to: '/profile',
          text: 'profile',
          submenus: []
        },
        {
          name: 'Account',
          to: '/account',
          text: 'account',
          submenus: []
        }
      ]
    }
  ],
  DEALER_USER: [
    {
      menu: [
        {
          name: 'Dashboard',
          to: '/dashboard',
          text: 'dashboard',
          submenus: []
        },
        {
          name: 'References',
          to: '/references',
          text: 'references',
          submenus: [
            {
              name: 'Customers',
              to: '/references/customers',
              text: 'customers'
            },
            {
              name: 'Destinations',
              to: '/references/destinations',
              text: 'destinations'
            },
            {
              name: 'Drivers',
              to: '/references/drivers',
              text: 'drivers'
            },
            {
              name: 'Locations',
              to: '/references/locations',
              text: 'locations'
            },
            {
              name: 'Notes',
              to: '/references/notes',
              text: 'notes'
            },
            {
              name: 'Products',
              to: '/references/products',
              text: 'products'
            },
            {
              name: 'Trucks',
              to: '/references/trucks',
              text: 'trucks'
            }
          ]
        },
        {
          name: 'Records',
          to: '/records',
          text: 'records',
          submenus: []
        }
      ],
      userMenu: [
        {
          name: 'Profile',
          to: '/profile',
          text: 'profile',
          submenus: []
        },
        {
          name: 'Account',
          to: '/account',
          text: 'account',
          submenus: []
        }
      ]
    }
  ],
  CLIENT_ADMIN: [
    {
      menu: [
        {
          name: 'Dashboard',
          to: '/dashboard',
          text: 'dashboard',
          tabs: []
        },
        {
          name: 'References',
          to: '/references',
          text: 'references',
          tabs: [
            {
              name: 'Customers',
              to: '/references/customers',
              text: 'customers'
            },
            {
              name: 'Destinations',
              to: '/references/destinations',
              text: 'destinations'
            },
            {
              name: 'Drivers',
              to: '/references/drivers',
              text: 'drivers'
            },
            {
              name: 'Locations',
              to: '/references/locations',
              text: 'locations'
            },
            {
              name: 'Notes',
              to: '/references/notes',
              text: 'notes'
            },
            {
              name: 'Products',
              to: '/references/products',
              text: 'products'
            },
            {
              name: 'Trucks',
              to: '/references/trucks',
              text: 'trucks'
            }
          ]
        },
        {
          name: 'Records',
          to: '/records',
          text: 'records',
          submenus: []
        }
      ],
      userMenu: [
        {
          name: 'User Settings',
          to: '/user-settings',
          text: 'user-settings',
          submenus: [
            {
              name: 'Profile',
              to: '/profile',
              text: 'profile'
            },
            {
              name: 'Account',
              to: '/account',
              text: 'account'
            }
          ]
        }
      ]
    }
  ],
  CLIENT_USER: [
    {
      menu: [
        {
          name: 'Dashboard',
          to: '/dashboard',
          text: 'dashboard',
          submenus: []
        },
        {
          name: 'References',
          to: '/references',
          text: 'references',
          submenus: [
            {
              name: 'Customers',
              to: '/references/customers',
              text: 'customers'
            },
            {
              name: 'Destinations',
              to: '/references/destinations',
              text: 'destinations'
            },
            {
              name: 'Drivers',
              to: '/references/drivers',
              text: 'drivers'
            },
            {
              name: 'Locations',
              to: '/references/locations',
              text: 'locations'
            },
            {
              name: 'Notes',
              to: '/references/notes',
              text: 'notes'
            },
            {
              name: 'Products',
              to: '/references/products',
              text: 'products'
            },
            {
              name: 'Trucks',
              to: '/references/trucks',
              text: 'trucks'
            }
          ]
        },
        {
          name: 'Records',
          to: '/records',
          text: 'records',
          submenus: []
        }
      ],
      userMenu: [
        {
          name: 'Profile',
          to: '/profile',
          text: 'profile',
          submenus: []
        },
        {
          name: 'Account',
          to: '/account',
          text: 'account',
          submenus: []
        }
      ]
    }
  ],
  footer: {}
};

export default navigationItems;
