import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import axios from 'axios';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
const queryClient = new QueryClient();
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
