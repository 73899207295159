import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import authHeader from './auth-header';
import Notification from '../Notifications/Notification';

const controller = new AbortController();

export interface ClientRecord {
  id: number;
  MachineName: string;
  JobName: string;
  JobNumber: string;
  ProductName: boolean;
  CustomerName: string;
  TruckName: string;
  DriverName: string;
  DestinationName: string;
  Text: string;
  CreationTimeDate: Date;
  StartTimeDate: Date;
  RecordTimeDate: Date;
  EndTimeDate: Date;
  Weight: number;
  UnitWeight: number;
  BucketCount: number;
}

// export type ClientRecord = {
//   id: number;
//   scaleName: string;
//   location?: string;
//   ipAddress?: string;
//   macAddress?: string;
//   imei?: string;
//   clientId: number;
//   brand?: string;
//   model?: string;
//   serialNumber?: string;
//   scaleInstallationDate?: Date;
//   modemActivationDate?: Date;
//   lanStatus: boolean;
//   scaleConnectionPackageId: number;
//   firstVerificationDate: Date;
//   firstVerificationPeriod: number;
//   verificationDocumentUrl: string;
//   lastServiceDate: Date;
//   nextServiceDate: Date;
//   timeZone: string;
//   dateTimeFormat: string;
//   updatedBy: number;
//   createdAt: Date;
//   updatedAt: Date;
//   lastUpdatedAt: Date;
// };

function useGetClientRecords(clientId: number) {
  return useQuery({
    queryKey: ['records', clientId],
    queryFn: async () => {
      return await axios.get(`/api/auth/v1/client/${clientId}/records`, {
        signal: controller.signal,
        headers: authHeader()
      });
    },
    onError: (error: any) => {
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Notification({messageTitle: 'Error', message: resMessage, messageType: 'danger'});
    }
  });
}

const getClientRecordById = async (clientId: number, recordId: number) => {
  const {data} = await axios.get(`/api/auth/v1/client/${clientId}/records/${recordId}`, {
    signal: controller.signal,
    headers: authHeader()
  });
  return data;
};

const RecordService = {
  useGetClientRecords,
  getClientRecordById
};

export default RecordService;
