import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../logo.png';
import {Icon} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';

import AuthService from '../services/auth.service';
import {navigationItems} from '../config/navigation';

const MuiNavBar = () => {
  const pages = ['Home', 'Contact Us'];
  const user = AuthService.getCurrentUser();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigation = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigation('/');
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pageUrl = (page) => {
    return page.toLowerCase().replace(' ', '-');
  };

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{mr: 2, align: 'center'}}>
            <img src={Logo} height={25} width={88} alt="logo" align="center" sx={{display: {xs: 'none', md: 'flex'}}} />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/home"
            sx={{
              mr: 10,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.05rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            Machine Weighing Data System
          </Typography>
          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'}
              }}
            >
              {user
                ? navigationItems[user.role][0].menu.map((menuItem) => (
                    <MenuItem key={pageUrl(menuItem.text)} onClick={handleCloseNavMenu}>
                      <Typography component={'span'} textAlign="center">
                        <Link style={{textDecoration: 'none', color: 'white'}} to={`${menuItem.to}`}>
                          {menuItem.name}
                        </Link>
                      </Typography>
                    </MenuItem>
                  ))
                : pages.map((page) => (
                    <MenuItem key={pageUrl(page)} onClick={handleCloseNavMenu}>
                      <Typography component={'span'} textAlign="center">
                        <Link style={{textDecoration: 'none', color: 'white'}} to={`/${pageUrl(page)}`}>
                          {page}
                        </Link>
                      </Typography>
                    </MenuItem>
                  ))}
            </Menu>
          </Box>

          <Icon sx={{display: {xs: 'flex', md: 'none'}, mr: 3}}>
            <img src={Logo} height={25} width={200} alt="logo" />
          </Icon>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 5 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: {xs: 'flex', md: 'none'},
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            MWDS
          </Typography>

          {user ? (
            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
              {navigationItems[user.role][0].menu.map((menuItem) => (
                <Button
                  key={pageUrl(menuItem.text)}
                  onClick={handleCloseNavMenu}
                  sx={{my: 2, color: 'white', display: 'block'}}
                >
                  <Link style={{textDecoration: 'none', color: 'white'}} to={`${menuItem.to}`}>
                    {menuItem.name}
                  </Link>
                </Button>
              ))}
            </Box>
          ) : (
            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
              {pages.map((page) => (
                <Button key={pageUrl(page)} onClick={handleCloseNavMenu} sx={{my: 2, color: 'white', display: 'block'}}>
                  <Link style={{textDecoration: 'none', color: 'white'}} to={`/${pageUrl(page)}`}>
                    {page}
                  </Link>
                </Button>
              ))}
            </Box>
          )}

          {!user ? (
            <Typography component={'span'} textAlign="center">
              <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                <Link style={{textDecoration: 'none', color: 'white'}} to={`/login`}>
                  Login
                </Link>
              </Box>
            </Typography>
          ) : (
            <Box sx={{flexGrow: 0}}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {navigationItems[user.role][0].userMenu.map((menuItem) => (
                  <MenuItem key={menuItem.text} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{menuItem.name}</Typography>
                  </MenuItem>
                ))}
                <MenuItem key="logout" onClick={handleLogout}>
                  <Typography textAlign="center" to="/">
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MuiNavBar;
