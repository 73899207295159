import {useEffect, useState} from 'react';
import AuthService from '../../services/auth.service';
import {BaseRef} from '../pages/ClientPages/ClientReferences/ClientBaseRef';
import MaterialReactTable, {MaterialReactTableProps, MRT_ColumnDef} from 'material-react-table';
import ScaleService from '../../services/scale.service';
import ReferenceService, {RefNames} from '../../services/reference.service';
import {Box, Button} from '@mui/material';
import {FileDownload} from '@mui/icons-material';
import {CreateNewReferenceModal} from '../References/ClientNewReferenceModal';
import Loading from '../Loading';
import parseAndExportToCSV from '../../utils/csvParser.util';

type Props = {
  refNames: RefNames;
  columns: MRT_ColumnDef<BaseRef>[];
  deleteClientRef: any;
};

const ClientReferenceTable = ({refNames, columns, deleteClientRef}: Props) => {
  const currentUser = AuthService.getCurrentUser();
  const clientId = currentUser?.clientId as number;
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const initialColumnVisibility = JSON.parse(
    localStorage.getItem(refNames.plural.toLowerCase() + 'ColumnVisibility') ?? '{}'
  );
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const initialDensity = JSON.parse(localStorage.getItem(refNames.plural.toLowerCase() + 'Density') ?? '{}');
  const [density, setDensity] = useState(initialDensity);
  const initialPagination = JSON.parse(
    localStorage.getItem(refNames.plural.toLowerCase() + 'Pagination') || '{"pageIndex": 0, "pageSize": 10}'
  ) as {pageIndex: number; pageSize: number};
  const [pagination, setPagination] = useState(initialPagination);

  useEffect(() => {
    localStorage.setItem(refNames.plural.toLowerCase() + 'ColumnVisibility', JSON.stringify(columnVisibility));
  }, [columnVisibility, refNames.plural]);

  useEffect(() => {
    localStorage.setItem(refNames.plural.toLowerCase() + 'Density', JSON.stringify(density));
  }, [density, refNames.plural]);

  useEffect(() => {
    localStorage.setItem(refNames.plural.toLowerCase() + 'Pagination', JSON.stringify(pagination));
  }, [pagination, pagination.pageIndex, pagination.pageSize, refNames.plural]);

  const {isLoading: isLoadingScalesData, data: scalesData} = ScaleService.useGetClientScalesAll(clientId);
  const {isLoading: isLoadingGetRef, data: tableData} = ReferenceService.useGetClientReferenceAll(clientId, refNames);

  const addClientRef = ReferenceService.useAddClientReference(clientId, refNames);
  const updateClientRef = ReferenceService.useUpdateClientReference(clientId, refNames);

  const handleCreateNewRow = (values: any) => {
    addClientRef.mutate(values);
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleSaveRowEdits: MaterialReactTableProps<BaseRef>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values
  }) => {
    if (!Object.keys(validationErrors).length) {
      const remappedObject: {[key: string]: any} = {} as BaseRef;
      for (const key in values) {
        if (key.includes('.')) {
          const parts = key.split('.');
          let currentObject = remappedObject;

          for (let i = 0; i < parts.length - 1; i++) {
            currentObject = currentObject[parts[i]] || (currentObject[parts[i]] = {});
          }
          currentObject[parts[parts.length - 1]] = values[key];
        } else {
          remappedObject[key] = values[key];
        }
      }
      const updatedValues = remappedObject;
      updateClientRef.mutate(updatedValues as any);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleExportData = () => {
    parseAndExportToCSV(tableData?.data, refNames.plural);
  };

  if (
    isLoadingScalesData ||
    isLoadingGetRef ||
    addClientRef.isLoading ||
    updateClientRef.isLoading ||
    deleteClientRef.isLoading
  )
    return (
      <div>
        <div style={{maxWidth: '100%'}}>
          <h1>{refNames.plural}</h1>
          <Loading isLoading={true} />
        </div>
      </div>
    );

  return (
    <div>
      <div style={{maxWidth: '100%'}}>
        <h1>{refNames.plural}</h1>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Edit',
              muiTableHeadCellProps: {
                align: 'center'
              },
              size: 40
            }
          }}
          columns={columns}
          data={tableData?.data || []}
          //renderDetailPanel={props.renderDetailPanel}
          state={{columnVisibility, density, pagination}}
          onPaginationChange={setPagination}
          onColumnVisibilityChange={setColumnVisibility}
          onDensityChange={setDensity}
          editingMode="row"
          enableRowNumbers
          enableRowActions
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          // renderRowActions={({ row, table }) => (
          //   <Box sx={{ display: "flex", gap: "1rem" }}>
          //     <Tooltip arrow placement="left" title="Edit">
          //       <IconButton onClick={() => table.setEditingRow(row)}>
          //         <Edit />
          //       </IconButton>
          //     </Tooltip>
          //     <Tooltip arrow placement="right" title="Delete">
          //       <IconButton color="error" onClick={() => handleDeleteRow(row)}>
          //         <Delete />
          //       </IconButton>
          //     </Tooltip>
          //   </Box>
          // )}
          // renderRowActions={({ row, table }) => (
          //   <Box sx={{ display: "flex", gap: "1rem" }}>
          //     {editEnabled && row.id === editableRow?.id ? (
          //       <>
          //         <Tooltip arrow placement="left" title="Cancel">
          //           <IconButton
          //             onClick={() => {
          //               handleCancelRowEdits();
          //               setEditEnabled(false);
          //             }}
          //           >
          //             <Cancel />
          //           </IconButton>
          //         </Tooltip>
          //         <Tooltip arrow placement="left" title="Save">
          //           <IconButton
          //             onClick={() => {
          //               //handleSaveRowEdits();
          //               //onEditingRowSave

          //               handleSaveRowEdits({ exitEditingMode: () => {}, row, table, values: row.newValue });
          //               alert("This should save but i have no clue what and how to hook this up");
          //               //handleSaveRowEdits;

          //               console.log("Save clicked");
          //             }}
          //           >
          //             <Save />
          //           </IconButton>
          //         </Tooltip>
          //       </>
          //     ) : (
          //       <>
          //         <Tooltip arrow placement="left" title="Edit">
          //           <IconButton
          //             onClick={() => {
          //               table.setEditingRow(row);
          //               setEditableRow(row);
          //               setEditEnabled(true);
          //             }}
          //           >
          //             <EditIcon />
          //           </IconButton>
          //         </Tooltip>
          //         <Tooltip arrow placement="right" title="Delete">
          //           <IconButton color="error" onClick={() => handleDeleteRow(row)}>
          //             <DeleteIcon />
          //           </IconButton>
          //         </Tooltip>
          //       </>
          //     )}
          //   </Box>
          // )}
          // muiTableBodyRowProps={({ row }) => ({
          //   onDoubleClick: (event) => {
          //     console.info(event, row.id);
          //   },
          //   sx: {
          //     cursor: "pointer", //you might want to change the cursor too when adding an onClick
          //   },
          // })}
          renderTopToolbarCustomActions={({table}: {table: any}) => (
            <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
              <Button color="secondary" onClick={() => setCreateModalOpen(true)} variant="contained">
                Create New {refNames.singular}
              </Button>
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownload />}
                variant="contained"
              >
                Export All Data
              </Button>
            </Box>
          )}
          //initialState={initialState}
        />
        <CreateNewReferenceModal
          refName={refNames.singular}
          columns={columns}
          clientScales={scalesData?.data || []}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSubmit={handleCreateNewRow}
        />
      </div>
    </div>
  );
};

export default ClientReferenceTable;
