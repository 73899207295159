import {BrowserRouter} from 'react-router-dom';
import MuiNavbar from './components/MuiNavbar';
import MainRoutes from './Routes';
import './App.css';
import {ReactNotifications} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <ReactNotifications />
        <MuiNavbar />
        <MainRoutes />
      </div>
    </BrowserRouter>
  );
};

export default App;
