import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {TokenDecoded, TokenResponse} from '../models/token.model';
import {User} from '../models/user.model';
import {UserRole} from '../enums/user-roles.enum';

const login = async (username: string, password: string): Promise<TokenResponse> => {
  try {
    const response = await axios.post<TokenResponse>(`${axios.defaults.baseURL}/api/login`, {
      email: username,
      password: password
    });

    if (response.data.accessToken) {
      const decoded = jwt_decode<TokenDecoded>(response.data.accessToken);
      
      console.log('Decoded token:', JSON.stringify(decoded, null, 2));

      // Function to map string role to enum
      const mapRoleToEnum = (role: string): UserRole | undefined => {
        return Object.values(UserRole).find(enumValue => enumValue === role);
      };

      console.log('mappedRole',mapRoleToEnum(decoded.role));
      
      
      const user: User = {
        userId: decoded.id,
        dealerId: decoded.dealerId,
        clientId: decoded.clientId,
        firstName: decoded.firstName,
        username: decoded.username,
        role: mapRoleToEnum(decoded.role) as UserRole,
        accessToken: response.data.accessToken
      };

      if (Object.values(UserRole).includes(user.role)) {
        if (user.role === UserRole.SUPERUSER) {
          user.path = 'superuser';
        } else if ([UserRole.DEALER_ADMIN, UserRole.DEALER_USER].includes(user.role)) {
          user.path = 'dealer';
        } else if ([UserRole.CLIENT_ADMIN, UserRole.CLIENT_USER].includes(user.role)) {
          user.path = 'client';
        }

        localStorage.setItem('user', JSON.stringify(user));
      } else {
        console.error('Role is undefined or not a valid UserRole');
      }
    }
    return response.data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};


const logout = (): void => {
  localStorage.removeItem('user');
  window.location.href = '/';
};

const getCurrentUser = (): User | null => {
  const userJson = localStorage.getItem('user');
  if (userJson) {
    const user: User = JSON.parse(userJson);
    const decodedToken = jwt_decode<TokenDecoded>(user.accessToken);
    if (decodedToken.exp * 1000 > Date.now()) {
      return user;
    } else {
      logout();
      return null;
    }
  }
  return null;
};

const AuthService = {
  login,
  logout,
  getCurrentUser
};

export default AuthService;
