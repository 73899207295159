import {MRT_Cell, MRT_ColumnDef} from 'material-react-table';
import {useCallback, useState} from 'react';
import {BaseRef} from '../pages/ClientPages/ClientReferences/ClientBaseRef';
import {
  validateAge,
  validateEmail,
  validateLicensePlate,
  validateRequired,
  validateTruckTarget,
  validateTruckWeight
} from './TextFieldValidations';

const GetEditTextFieldProps = (cell: MRT_Cell<BaseRef>) => {
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const getEditTextFieldProps = useCallback(
    (cell: MRT_Cell<BaseRef>): MRT_ColumnDef<BaseRef>['muiTableBodyCellEditTextFieldProps'] => {
      const validate = (value: any) => {
        switch (cell.column.id) {
          case 'email':
            return validateEmail(value);
          case 'age':
            return validateAge(+value);
          case 'Weight':
            return validateTruckWeight(+value);
          case 'Target':
            return validateTruckTarget(+value);
          case 'Name':
            return validateLicensePlate(value);
          default:
            return validateRequired(value);
        }
      };

      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validate(event.target.value);
          if (!isValid) {
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`
            });
          } else {
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors
            });
          }
        }
      };
    },
    [validationErrors]
  );
  return getEditTextFieldProps;
};
export default GetEditTextFieldProps;
