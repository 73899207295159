import React, {useState} from 'react';
import {MRT_ColumnDef} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import {BaseRef} from '../pages/ClientPages/ClientReferences/ClientBaseRef';
import {ClientScale} from '../../services/scale.service';

type Props = {
  refName: string;
  columns: MRT_ColumnDef<BaseRef>[];
  clientScales: any[];
  onClose: () => void;
  onSubmit: (values: BaseRef) => void;
  open: boolean;
};

export const CreateNewReferenceModal = ({open, columns, onClose, onSubmit, refName, clientScales}: Props) => {
  const [values, setValues] = useState<BaseRef>(() =>
    columns.reduce((acc, column) => {
      const accessorKey = column.accessorKey ?? '';
      if (accessorKey !== 'scale.scaleName') {
        acc[column.accessorKey ?? ''] = '';
      } else {
        acc['scale'] = {id: '', scaleName: ''};
      }
      return acc;
    }, {} as any)
  );

  const [scaleValue, setScaleValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState('');

  const handleChangeScaleValue = (event: SelectChangeEvent) => {
    setScaleValue(event.target.value);
    setValues({...values, scale: {...values.scale, id: event.target.value as unknown as number}}); // should be event.target.name BUT ACTUAL VALUE NOT name should be stored
  };

  const handleChangeStatusValue = (event: SelectChangeEvent) => {
    setStatusValue(event.target.value);
    setValues({...values, [event.target.name]: event.target.value});
  };

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle textAlign="center">Create New {refName}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={1}>
            <Grid item xs={0} sm={6}>
              {columns.map((column) => {
                return column.accessorKey === 'scale.id' ? (
                  <Box
                    key={column.accessorKey}
                    sx={{
                      paddingRight: 4,
                      paddingLeft: 2,
                      mb: 2,
                      mt: 3
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      required
                      size="small"
                      sx={{
                        minWidth: '100%'
                      }}
                    >
                      <InputLabel id="select-label">{column.header}</InputLabel>
                      <Select
                        labelId="select-label"
                        //id={"select-${scale.id}"}
                        id={column.accessorKey}
                        value={scaleValue}
                        name={column.accessorKey}
                        label="Scale"
                        onChange={handleChangeScaleValue}
                      >
                        {clientScales.map((scale: ClientScale, index) => (
                          <MenuItem key={index} value={scale.id}>
                            {scale.scaleName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : column.accessorKey === 'Available' ? (
                  <Box
                    key={column.accessorKey}
                    sx={{
                      paddingRight: 4,
                      paddingLeft: 2,
                      mb: 2,
                      mt: 3
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      required
                      size="small"
                      key={column.accessorKey}
                      sx={{
                        minWidth: '100%'
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">{column.header}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={statusValue}
                        // name="Status"
                        name={column.accessorKey}
                        label="Status"
                        onChange={handleChangeStatusValue}
                      >
                        <MenuItem key={1} value={1}>
                          Active
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          Inactive
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                ) : !(column.enableEditing === false) ? (
                  <Box
                    key={column.accessorKey}
                    sx={{
                      paddingRight: 4,
                      paddingLeft: 2,
                      mb: 2,
                      mt: 3
                    }}
                  >
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      size="small"
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                    />
                  </Box>
                ) : null;
              })}
            </Grid>
            <Grid item xs={0} sm={6}>
              {columns.map((column) => {
                return column.enableEditing === false ? (
                  <Box
                    key={column.accessorKey}
                    sx={{
                      paddingRight: 4,
                      paddingLeft: 2,
                      mb: 2,
                      mt: 3
                    }}
                  >
                    <TextField
                      size="small"
                      disabled
                      sx={{
                        minWidth: '100%'
                      }}
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                    />
                  </Box>
                ) : null;
              })}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{p: '1.25rem'}}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New {refName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
