import AuthService from '../../services/auth.service';
import MaterialReactTable, {MRT_ColumnDef, MRT_Row, MRT_TableInstance} from 'material-react-table';
import {RefNames} from '../../services/reference.service';
import {Box, Button} from '@mui/material';
import {FileDownload} from '@mui/icons-material';
import Loading from '../Loading';
import RecordService, {ClientRecord} from '../../services/record.service';
import {useEffect, useRef, useState} from 'react';
import parseAndExportToCSV from '../../utils/csvParser.util';

type Props = {
  refNames: RefNames;
  columns: MRT_ColumnDef<ClientRecord>[];
};

const ClientRecordsTable = ({refNames, columns}: Props) => {
  const currentUser = AuthService.getCurrentUser();
  const clientId = currentUser?.clientId as number;
  const initialColumnVisibility = JSON.parse(localStorage.getItem('recordsColumnVisibility') ?? '{}');
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const initialDensity = JSON.parse(localStorage.getItem('recordsDensity') ?? '{}');
  const [density, setDensity] = useState(initialDensity);
  const initialPagination = JSON.parse(
    localStorage.getItem('recordsPagination') || '{"pageIndex": 0, "pageSize": 10}'
  ) as {pageIndex: number; pageSize: number};

  const [pagination, setPagination] = useState(initialPagination);

  const {isLoading: isLoadingGetRef, data: tableData} = RecordService.useGetClientRecords(clientId);
  const tableInstanceRef = useRef<MRT_TableInstance<ClientRecord>>(null);

  useEffect(() => {
    localStorage.setItem('recordsColumnVisibility', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  useEffect(() => {
    localStorage.setItem('recordsDensity', JSON.stringify(density));
  }, [density]);

  useEffect(() => {
    localStorage.setItem('recordsPagination', JSON.stringify(pagination));
  }, [pagination, pagination.pageIndex, pagination.pageSize]);

  const handleExportData = (rows: MRT_Row<ClientRecord>[]) => {
    parseAndExportToCSV(
      rows.map((row) => row.original),
      refNames.plural
    );
  };

  interface DataObject {
    [key: string]: any;
  }

  const handleExportRows = (data: DataObject[]): void => {
    parseAndExportToCSV(data, refNames.plural);
  };

  if (isLoadingGetRef)
    return (
      <div>
        <div style={{maxWidth: '100%'}}>
          <h1>{refNames.plural}</h1>
          <Loading isLoading={true} />
        </div>
      </div>
    );

  return (
    <div>
      <div style={{maxWidth: '100%'}}>
        <h1>{refNames.plural}</h1>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Edit',
              muiTableHeadCellProps: {
                align: 'center'
              },
              size: 40
            }
          }}
          columns={columns}
          data={tableData?.data || []}
          tableInstanceRef={tableInstanceRef}
          state={{columnVisibility, density, pagination}}
          onPaginationChange={setPagination}
          onColumnVisibilityChange={setColumnVisibility}
          onDensityChange={setDensity}
          enableRowNumbers
          renderTopToolbarCustomActions={({table}: {table: any}) => (
            <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
              <Button
                onClick={() => {
                  handleExportData(table.getPrePaginationRowModel().rows);
                }}
                startIcon={<FileDownload />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                //disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows.map((row: any) => row._valuesCache))
                }
                startIcon={<FileDownload />}
                variant="contained"
              >
                Export Visible Columns
              </Button>
            </Box>
          )}
        />
      </div>
    </div>
  );
};

export default ClientRecordsTable;
