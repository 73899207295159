import {DateTime} from 'luxon';

const currentDateTimeFormattedEE = () => {
  return DateTime.now().toFormat('dd-MM-yyyy_HHmmss');
};

const utcDateTimeWithTimeZoneToDate = (dateTime) => {
  if (!dateTime) {
    return '';
  }
  const dt = DateTime.fromISO(dateTime);
  const dtFormatted = dt.toLocaleString({day: 'numeric', month: 'long', year: 'numeric'});
  return dtFormatted;
};

const utcDateTimeWithTimeZoneToDateTime = (dateTime) => {
  if (!dateTime) {
    return '';
  }
  const dt = DateTime.fromISO(dateTime);
  const dtFormatted = dt.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
  return dtFormatted;
};

const convertUtcDateTimeToLocalDateTime = (dateTimeUTC) => {
  if (!dateTimeUTC) {
    return '';
  }
  return DateTime.fromJSDate(dateTimeUTC).toFormat('dd/MM/yyyy HH:mm:ss');
};

const inFuture = (dateTime) => {
  if (!dateTime) {
    return 'no date set';
  }
  const now = DateTime.now();
  const dt = DateTime.fromISO(dateTime);
  const diffInDays = dt.diff(now, 'days');
  const diffValue = diffInDays?.values.days;
  if (diffValue < 0) {
    return 'time for service';
  }
  if (diffValue < 2) {
    return 'today';
  }
  if (diffValue < 31) {
    const result = Math.round(diffValue);
    return 'in ' + result + ' days';
  }
  if (diffValue < 61) {
    const diffInMonths = diffInDays.as('months');
    const result = Math.round(diffInMonths);
    return 'in ' + result + ' month';
  }
  if (diffValue < 365) {
    const diffInMonths = diffInDays.as('months');
    const result = Math.round(diffInMonths);
    return 'in ' + result + ' months';
  }
  if (diffValue < 548) {
    const diffInYears = diffInDays.as('years');
    const result = Math.round(diffInYears);
    return 'in ' + result + ' year';
  }
  if (diffValue >= 548) {
    const diffInYears = diffInDays.as('years');
    const result = Math.round(diffInYears);
    return 'in ' + result + ' years';
  }
};

const timeAgo = (dateTime) => {
  if (!dateTime || undefined) {
    return 'scale has not been online yet';
  }
  const now = DateTime.now();
  const dt = DateTime.fromISO(dateTime);
  const diffInSeconds = now.diff(dt, 'seconds');
  const diffValue = diffInSeconds.values.seconds;
  if (diffValue < 60) {
    const result = Math.round(diffValue);
    if (result < 2) {
      return result + ' second ago';
    }
    return result + ' seconds ago';
  }
  if (diffValue < 1200) {
    const diffInMinutes = diffInSeconds.as('minutes');
    const result = Math.round(diffInMinutes);
    if (result < 2) {
      return result + ' minute ago';
    } else {
      return result + ' minutes ago';
    }
  }
  if (diffValue < 86400) {
    const diffInHours = diffInSeconds.as('hours');
    const result = Math.round(diffInHours);
    if (result < 2) {
      return result + ' hour ago';
    } else {
      return result + ' hours ago';
    }
  }
  if (diffValue < 2678400) {
    const diffInDays = diffInSeconds.as('days');
    const result = Math.round(diffInDays);
    if (result < 2) {
      return result + ' day ago';
    } else {
      return result + ' days ago';
    }
  }

  if (diffValue < 31557600) {
    const diffInMonths = diffInSeconds.as('months');
    const result = Math.round(diffInMonths);
    if (result < 2) {
      return result + ' month ago';
    } else {
      return result + ' months ago';
    }
  }
  if (diffValue >= 31557600) {
    const diffInYears = diffInSeconds.as('years');
    const result = Math.round(diffInYears);
    if (result < 2) {
      return result + ' year ago';
    } else {
      return result + ' years ago';
    }
  }
};

const DateTimeConverter = {
  inFuture,
  timeAgo,
  utcDateTimeWithTimeZoneToDate,
  utcDateTimeWithTimeZoneToDateTime,
  currentDateTimeFormattedEE,
  convertUtcDateTimeToLocalDateTime
};

export default DateTimeConverter;
