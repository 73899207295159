import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';

export const Error404 = () => {
  return (
    <div>
      <Container>
        <Alert severity="warning">404 - Page Not Found!</Alert>
      </Container>
    </div>
  );
};
