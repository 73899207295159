import {useMemo} from 'react';
import {MRT_ColumnDef} from 'material-react-table';
import {RefNames} from '../../../../services/reference.service';
import ClientRecordsTable from '../../../Tables/ClientRecordsBaseTable';
import {ClientRecord} from '../../../../services/record.service';
import {Box} from '@mui/system';

const refNames: RefNames = {singular: 'Record', plural: 'Records', propName: 'Name'};

const ClientRecords = () => {
  const columns = useMemo<MRT_ColumnDef<ClientRecord>[]>(
    () => [
      {
        accessorKey: 'id', // sample.idx access nested data with dot notation
        header: 'id',
        size: 10,
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'MachineName',
        header: 'Scale',
        size: 200,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'JobName', //normal accessorKey
        header: 'Job Name',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'JobNumber',
        header: 'Job Number',
        size: 50,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'ProductName',
        header: 'Product',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'CustomerName',
        header: 'Customer',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'TruckName',
        header: 'Truck',
        size: 50,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'DriverName',
        header: 'Truck Driver',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Text',
        header: 'Info Note',
        size: 200,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'CreationTimeDate',
        header: 'Creation TimeDate',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({cell}) => {
          const isoDateString = cell.getValue<Date>();
          const date = new Date(isoDateString);
          const localDateString = date.toLocaleDateString();
          const localTimeString = date.toLocaleTimeString();
          return (
            <Box>
              <div>{localDateString}</div>
              <div>{localTimeString}</div>
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'StartTimeDate',
        header: 'Start TimeDate',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({cell}) => {
          const isoDateString = cell.getValue<Date>();
          const date = new Date(isoDateString);
          const localDateString = date.toLocaleDateString();
          const localTimeString = date.toLocaleTimeString();
          return (
            <Box>
              <div>{localDateString}</div>
              <div>{localTimeString}</div>
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'RecordTimeDate',
        header: 'Record TimeDate',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({cell}) => {
          const isoDateString = cell.getValue<Date>();
          const date = new Date(isoDateString);
          const localDateString = date.toLocaleDateString();
          const localTimeString = date.toLocaleTimeString();
          return (
            <Box>
              <div>{localDateString}</div>
              <div>{localTimeString}</div>
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'EndTimeDate',
        header: 'End TimeDate',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({cell}) => {
          const isoDateString = cell.getValue<Date>();
          const date = new Date(isoDateString);
          const localDateString = date.toLocaleDateString();
          const localTimeString = date.toLocaleTimeString();
          return (
            <Box>
              <div>{localDateString}</div>
              <div>{localTimeString}</div>
            </Box>
          );
        },
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Weight',
        header: 'Weight (kg)',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        },
        Cell: ({cell}) => (
          <>
            {cell.getValue<number>()?.toLocaleString?.('et-EE', {
              // currency: 'EUR',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </>
        )
      },
      {
        accessorKey: 'UnitWeight',
        header: 'Unit Weight (Tons)',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        },
        Cell: ({cell}) => (
          <>
            {cell.getValue<number>()?.toLocaleString?.('et-EE', {
              // currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 3
            })}
          </>
        )
      },
      {
        accessorKey: 'BucketCount',
        header: 'Bucket Count',
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        },
        Cell: ({cell}) => (
          <>
            {cell.getValue<number>()?.toLocaleString?.('et-EE', {
              // currency: 'EUR',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </>
        )
      }
    ],
    []
  );

  return (
    <div>
      <ClientRecordsTable
        refNames={refNames}
        columns={columns}
        // renderDetailPanel={({ row }: { row: any }) => (
        //   <Box
        //     sx={{
        //       display: "grid",
        //       margin: "auto",
        //       gridTemplateColumns: "1fr 1fr",
        //       width: "80%",
        //     }}
        //   >
        //     <Typography>State: {row.original.State}</Typography>
        //     <Typography>Zip Code: {row.original.ZipCode}</Typography>
        //     <Typography>City: {row.original.City}</Typography>
        //     <Typography>E-mail: {row.original.Email}</Typography>
        //     <Typography>Street: {row.original.Street}</Typography>
        //     <Typography>Phone: {row.original.PhoneNumber}</Typography>
        //   </Box>
        // )}
        // initialState={{
        //   columnVisibility: {
        //     State: false,
        //     ZipCode: false,
        //     City: false,
        //     Email: false,
        //     Street: false,
        //     PhoneNumber: false,
        //   },
        // }}
      />
    </div>
  );
};

export default ClientRecords;
