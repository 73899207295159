import {Navigate, Outlet} from 'react-router-dom';
import AuthService from '../services/auth.service';
import {UserRole} from '../enums/user-roles.enum';

const useAuth = () => {
  const _user = AuthService.getCurrentUser();

  if (_user) {
    return {
      auth: true,
      role: _user.role as UserRole
    };
  } else {
    return {
      auth: false,
      role: null
    };
  }
};

type ProtectedRouteProps = {
  roleRequired?: (
    | UserRole.SUPERUSER
    | UserRole.DEALER_ADMIN
    | UserRole.DEALER_USER
    | UserRole.CLIENT_ADMIN
    | UserRole.CLIENT_USER
  )[];
  redirectPath?: string;
};

const ProtectedRoutes = ({roleRequired = [], redirectPath = '/'}: ProtectedRouteProps) => {
  const {auth, role} = useAuth();

  if (!auth) {
    console.log('No auth');
    
    return <Navigate to={redirectPath} replace />;
  }
  
  if (role && !roleRequired?.includes(role)) {
    console.log('No role');
    return <Navigate to="denied" />;
  }
  
  console.log('To Outlet');
  return <Outlet />;
};

export default ProtectedRoutes;
