import {Store} from 'react-notifications-component';

interface NotificationProps {
  messageTitle: string;
  message: string;
  messageType: 'success' | 'danger' | 'info' | 'default' | 'warning';
}

function Notification({messageTitle, message, messageType}: NotificationProps) {
  Store.addNotification({
    title: messageTitle,
    message: message,
    type: messageType,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
}

export default Notification;
