import dateTimeFormats from './dateTimeConverter.util';
const Papa = require('papaparse');

const config = {
  quotes: true, //or array of booleans
  quoteChar: '"',
  escapeChar: '"',
  delimiter: ';',
  header: true,
  newline: '\r\n',
  bom: true,
  encoding: 'ISO-8859-1',
  skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
  columns: null //or array of strings
};

interface DataObject {
  [key: string]: any;
}

function parseAndExportToCSV(data: DataObject[], filename: string): void {
  // Convert array of objects to CSV string
  data.forEach((obj) => {
    if ('MachineName' in obj) {
      delete obj['MachineName'];
    }
    // Remove any nested object in data
    Object.entries(obj).forEach(([key, value]) => {
      if (key.match(/.*TimeDate.*/)) {
        let date = new Date(obj[key]);
        obj[key] = dateTimeFormats.convertUtcDateTimeToLocalDateTime(date);
      }
      if (key.match(/.*Weight.*/)) {
        obj[key] = value.toString().replace(/\./g, ',');
      }
      if (typeof value === 'object') {
        if (key !== 'scale') {
          delete obj[key];
        } else if (value && typeof value === 'object' && 'scaleName' in value) {
          obj[key] = value['scaleName'];
        }
      }
    });
  });

  const csv = Papa.unparse(data, [config]);

  // Create a Blob object from the CSV string
  const blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=UTF-8;'});

  // Create a link element to download the CSV file
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename + ' - ' + dateTimeFormats.currentDateTimeFormattedEE() + '.csv';

  // Add link to DOM and click it to start download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default parseAndExportToCSV;
