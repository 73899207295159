const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const validateLicensePlate = (licensePlate: string) =>
  !!licensePlate.length && licensePlate.toUpperCase().match(/^\d{1,3}[a-zA-Z]{3}(?=.{0,6}$)/);

const validateAge = (age: number) => age >= 18 && age <= 50;
const validateTruckWeight = (weight: number) => weight >= 100 && weight <= 50000;
const validateTruckTarget = (target: number) => target >= 100 && target <= 50000;

export {validateRequired, validateEmail, validateLicensePlate, validateAge, validateTruckWeight, validateTruckTarget};
