import {Outlet} from 'react-router-dom';
import TabsNav from './TabsNav';

const Tabs = (props: any) => {
  return (
    <div>
      {/** Tab navigation  */}
      <TabsNav onTabsStatChange={props.onTabChange} {...props} />
      {/** Tab inner content */}
      <Outlet />
    </div>
  );
};

export default Tabs;
