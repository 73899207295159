import {Container} from '@mui/material';

export const Home = () => {
  return (
    <Container>
      <div>
        <h3>Homepage</h3>
        <h1>Back-End URL: {process.env.REACT_APP_BASE_URL}</h1>
      </div>
    </Container>
  );
};
