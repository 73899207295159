import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AuthService from '../../services/auth.service';
import {useNavigate} from 'react-router-dom';
import BasicAlerts from '../Alert';

import CircularProgress from '@mui/material/CircularProgress';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={process.env.REACT_APP_BASE_URL}>
        Machine Weighing Data System
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [loading, setLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [helperState, setHelperState] = React.useState(false);
  const [helperMessage, setHelperMessage] = React.useState('');
  let navigate = useNavigate();
  const emailRegex = /(^([0-9a-zA-Z](['-.\w]*[0-9a-zA-Z][-\w])*@([0-9a-zA-Z][-.\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$)|\s*/;

  const handleSubmit = (event) => {
    setErrorState(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!event.currentTarget.email.value.match(emailRegex)) {
      setHelperState(true);
      setHelperMessage('Invalid email format');
    } else {
      setLoading(true);
      setHelperState(false);
      AuthService.login(data.get('email'), data.get('password')).then(
        () => {
          navigate('/');
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          setLoading(false);
          setErrorState(true);
          setErrorMessage(resMessage);
        }
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} validate={+true} sx={{mt: 1}}>
            <TextField
              // ref={emailField}
              error={helperState}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              helperText={helperMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {loading && (
              <Box sx={{width: '100%', display: 'flex'}} spacing={2} variant="contained">
                <CircularProgress sx={{ml: 1, p: 1}} />
                <Typography sx={{ml: 1, p: 1}}>Loading...</Typography>
              </Box>
            )}
            {errorState && <BasicAlerts severity="error" message={errorMessage} />}

            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
    </ThemeProvider>
  );
}
