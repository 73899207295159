import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import {AlertTitle} from '@mui/material';

export const Error403 = () => {
  return (
    <div>
      <Container>
        <Alert severity="warning">
          <AlertTitle>403 - Access denied!</AlertTitle>
          If you believe you should have access to this page, please contact customer support.
        </Alert>
      </Container>
    </div>
  );
};
