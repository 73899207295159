import authHeader from './auth-header';
import axios from 'axios';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import Notification from '../Notifications/Notification';

const controller = new AbortController();

export interface RefNames {
  singular: string;
  plural: string;
  propName: string;
}

function useGetClientReferenceAll(clientId: number, refNames: any) {
  return useQuery({
    queryKey: [`${refNames.plural.toLowerCase()}`, clientId],
    queryFn: async () => {
      return await axios.get(`/api/auth/v1/client/${clientId}/reference/${refNames.plural.toLowerCase()}`, {
        signal: controller.signal,
        headers: authHeader()
      });
    },
    onError: (error: any) => {
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Notification({
        messageTitle: 'Error',
        message: resMessage,
        messageType: 'danger'
      });
    }
  });
}

const getClientReferenceById = async (clientId: number, refName: string, refId: number) => {
  return await axios.get(`/api/auth/v1/client/${clientId}/reference/${refName}/${refId}`, {
    signal: controller.signal,
    headers: authHeader()
  });
};

function useAddClientReference(clientId: number, refNames: any) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axios.post(`/api/auth/v1/client/${clientId}/reference/${refNames.plural.toLowerCase()}`, data, {
        signal: controller.signal,
        headers: authHeader()
      });
    },
    // onMutate: (values) => {
    //   // A mutation is about to happen!

    //   // Optionally return a context containing data to use when for example rolling back
    //   return { id: 1 };
    // },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({queryKey: [`${refNames.plural.toLowerCase()}`]});
      Notification({
        messageTitle: 'Success',
        message: data.data.dataMessage,
        messageType: 'success'
      });
    },
    onError: (error: any) => {
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Notification({
        messageTitle: 'Error',
        message: resMessage,
        messageType: 'danger'
      });
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    }
  });
}

function useUpdateClientReference(clientId: number, refNames: any) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      return await axios.put(
        `/api/auth/v1/client/${clientId}/reference/${refNames.plural.toLowerCase()}/${data.id}`,
        data,
        {
          signal: controller.signal,
          headers: authHeader()
        }
      );
    },
    // onMutate: (values) => {
    //   // A mutation is about to happen!

    //   // Optionally return a context containing data to use when for example rolling back
    //   return { id: 1 };
    // },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({queryKey: [`${refNames.plural.toLowerCase()}`]});
      Notification({
        messageTitle: 'Success',
        message: data.data.dataMessage,
        messageType: 'success'
      });
    },
    onError: (error: any) => {
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Notification({
        messageTitle: 'Error',
        message: resMessage,
        messageType: 'danger'
      });
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    }
  });
}

function useDeleteClientReference(clientId: number, refNames: any) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (refId: any) => {
      return await axios.delete(`/api/auth/v1/client/${clientId}/reference/${refNames.plural.toLowerCase()}/${refId}`, {
        signal: controller.signal,
        headers: authHeader()
      });
    },
    // onMutate: (values) => {
    //   // A mutation is about to happen!

    //   // Optionally return a context containing data to use when for example rolling back
    //   return { id: 1 };
    // },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({queryKey: [`${refNames.plural.toLowerCase()}`]});
      Notification({
        messageTitle: 'Success',
        message: data.data.dataMessage,
        messageType: 'success'
      });
    },
    onError: (error: any) => {
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Notification({
        messageTitle: 'Error',
        message: resMessage,
        messageType: 'danger'
      });
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    }
  });
}

const ReferenceService = {
  useGetClientReferenceAll,
  getClientReferenceById,
  useAddClientReference,
  useUpdateClientReference,
  useDeleteClientReference
};

export default ReferenceService;
