import UserService from '../../services/user.service';
import {useState, useEffect} from 'react';
import AuthService from '../../services/auth.service';
import DateTimeConverter from '../../utils/dateTimeConverter.util';
//import { useNavigate } from "react-router-dom";
import {Container, Table} from '@mui/material';

export const BoardClient = () => {
  const currentUser = AuthService.getCurrentUser();
  const clientId = currentUser?.clientId;
  const [scales, setScales] = useState([]);
  // const [dataProducts, setDataProducts] = useState([]);
  // const [dataProductsToday, setDataProductsToday] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const request = await UserService.getClientScales(clientId);
        setScales(request.data.data);
        return request;
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [clientId]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const startDate = DateTime.now().minus({ days: 1 }).toISODate();
  //     const endDate = DateTime.now().toISODate();
  //     try {
  //       const request = await UserService.getClientProducts(clientId, startDate, endDate);
  //       setDataProducts(request.data.data);
  //       return request;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, [clientId]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const startDate = DateTime.now().toISODate();
  //     const endDate = DateTime.now().plus({ days: 1 }).toISODate();
  //     try {
  //       const request = await UserService.getClientProducts(clientId, startDate, endDate);
  //       setDataProductsToday(request.data.data);
  //       return request;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, [clientId]);

  const renderScale = (scale, index) => {
    return (
      <tr key={index}>
        <td>{scale?.location}</td>
        <td>{scale?.scaleName}</td>
        <td>
          {DateTimeConverter.utcDateTimeWithTimeZoneToDateTime(scale?.lastUpdatedAt)}
          {' ('}
          {DateTimeConverter.timeAgo(scale?.lastUpdatedAt)}
          {')'}
        </td>
        <td>
          {DateTimeConverter.utcDateTimeWithTimeZoneToDate(scale?.nextServiceDate)}
          {' ('}
          {DateTimeConverter.inFuture(scale?.nextServiceDate)}
          {')'}
        </td>
      </tr>
    );
  };

  // const columnsProducts = useMemo(
  //   () => [
  //     { Header: "Location", id: "location", accessor: "LocationName" },
  //     { Header: "Material", id: "material", accessor: "ProductName" },
  //     { Header: "Total (in tons)", id: "total", accessor: "Total" },
  //   ],
  //   []
  // );

  return (
    <div>
      <Container>
        <h1>Scales services and activity report</h1>
        <Table>
          <thead>
            <tr>
              <th>Scale Location</th>
              <th>Scale Name</th>
              <th>Last Active (time ago)</th>
              <th>Next Service Date (in future)</th>
            </tr>
          </thead>
          <tbody>{scales.map(renderScale)}</tbody>
        </Table>

        <h1>Material Overview - Yesterday</h1>
        {/* <TableClientMaterials columns={columnsProducts} data={dataProducts} /> */}

        <h1>Material Overview - Today</h1>
        {/* <TableClientMaterials columns={columnsProducts} data={dataProductsToday} /> */}
      </Container>
    </div>
  );
};
