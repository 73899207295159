import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {navigationItems} from '../../config/navigation';
import {Link} from 'react-router-dom';

function createTabsList(props) {
  if (props.role) {
    const navTabs = navigationItems[`${props.role}`][0].menu
      .filter((obj) => obj.text === props.path)
      .flatMap(({tabs}) => {
        return tabs;
      });
    return navTabs;
  }
}

const TabNav = (props) => {
  const tabs = createTabsList(props);
  const [currentTab, setCurrentTab] = React.useState(props.activeTabValue);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    const referencesActiveTab = {value: newValue, text: tabs[newValue].text};
    const newTabName = tabs[newValue].text;
    const newTabValue = newValue;
    sessionStorage.setItem('referencesActiveTab', JSON.stringify(referencesActiveTab));
    props.onTabsStatChange(newTabValue, newTabName);
  };

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
          {tabs?.map((tabItem, index) => (
            <Tab key={index} label={tabItem?.name} value={index} to={tabItem?.to} component={Link} />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default TabNav;
