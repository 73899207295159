import {Routes, Route, Navigate} from 'react-router-dom';

import {Home} from './components/pages/Home';
import {ContactUs} from './components/pages/ContactUs';
//import { About } from "./components/About";
import Login from './components/pages/Login';
import Tabs from './components/References/Tabs';

//import { Profile } from "./components/Profile";

import {BoardSuperuser} from './components/Dashboards/BoardSuperuser';
import {BoardDealer} from './components/Dashboards/BoardDealer';
import {BoardClient} from './components/Dashboards/BoardClient';

import Records from './components/pages/ClientPages/ClientRecords/Records';
import ClientCustomers from './components/pages/ClientPages/ClientReferences/ClientCustomers';
import ClientDestinations from './components/pages/ClientPages/ClientReferences/ClientDestinations';
import ClientDrivers from './components/pages/ClientPages/ClientReferences/ClientDrivers';
import ClientLocations from './components/pages/ClientPages/ClientReferences/ClientLocations';
import ClientNotes from './components/pages/ClientPages/ClientReferences/ClientNotes';
import ClientProducts from './components/pages/ClientPages/ClientReferences/ClientProducts';
import ClientTrucks from './components/pages/ClientPages/ClientReferences/ClientTrucks';
// import ClientTruck from "./components/References/ClientTruck";

import ProtectedRoutes from './components/ProtectedRoutes';
import {Error404} from './components/pages/Error404';
import {Error403} from './components/pages/Error403';
import AuthService from './services/auth.service';
import {useState} from 'react';
import { UserRole } from './enums/user-roles.enum';

const MainRoutes = () => {
  const user = AuthService.getCurrentUser();
  const activeTab = JSON.parse(sessionStorage.getItem('referencesActiveTab') ?? '{}');
  const activeReferenceTabName = activeTab.text;
  const activeReferenceTabValue = Number(activeTab.value);
  const [currentTabValue, setCurrentTabValue] = useState(activeReferenceTabValue || 0);
  const [currentTabName, setCurrentTabName] = useState(activeReferenceTabName || 'customers');

  function handleTabChange(newTabValue: number, newTabName: string) {
    setCurrentTabValue(newTabValue);
    setCurrentTabName(newTabName);
  }

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/login" element={<Login />} />
      {user?.role === UserRole.SUPERUSER && (
        <Route element={<ProtectedRoutes roleRequired={[UserRole.SUPERUSER]} />}>
          <Route path="dashboard" element={<BoardSuperuser />} />
          {/* <Route path="dealers" element={<Dealers />} /> */}
          {/* <Route path="clients" element={<Clients />} /> */}
        </Route>
      )}
      {(user?.role === 'DEALER_ADMIN' || user?.role === UserRole.DEALER_USER) && (
        <Route element={<ProtectedRoutes roleRequired={[UserRole.DEALER_ADMIN, UserRole.DEALER_USER]} />}>
          <Route path="dashboard" element={<BoardDealer />} />
          <Route path="references" />
          {/* <Route path="references/customers" element={<ReferenceCustomers />} />
                <Route path="references/destinations" element={<ReferenceDestinations />} /> */}
        </Route>
      )}
      {(user?.role === 'CLIENT_ADMIN' || user?.role === UserRole.CLIENT_USER) && (
        <Route element={<ProtectedRoutes roleRequired={[UserRole.CLIENT_ADMIN, UserRole.CLIENT_USER]} />}>
          <Route path="dashboard" element={<BoardClient />} />
          <Route path="records" element={<Records />} />
          <Route
            path="references"
            element={
              <Tabs role={user.role} path="references" onTabChange={handleTabChange} activeTabValue={currentTabValue} />
            }
          >
            <Route path="/references" element={<Navigate replace to={currentTabName} />} />
            <Route path="customers" element={<ClientCustomers />} />
            <Route path="destinations" element={<ClientDestinations />} />
            <Route path="drivers" element={<ClientDrivers />} />
            <Route path="locations" element={<ClientLocations />} />
            <Route path="notes" element={<ClientNotes />} />
            <Route path="products" element={<ClientProducts />} />
            <Route path="trucks" element={<ClientTrucks />} />
            {/* <Route path="trucks/:truckId" element={<ClientTruck />} /> */}
          </Route>
        </Route>
      )}
      <Route />
      <Route path="denied" element={<Error403 />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default MainRoutes;
