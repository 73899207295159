import {MRT_Row} from 'material-react-table';
import {RefNames} from '../../services/reference.service';
import {BaseRef} from '../pages/ClientPages/ClientReferences/ClientBaseRef';

interface Props {
  deleteClientRef: any;
  row: MRT_Row<BaseRef>;
  refNames: RefNames;
}

const DeleteRow = (props: Props) => {
  const {deleteClientRef, row, refNames} = props;

  const deleteRefWithId = row.original.id;
  if (
    !window.confirm(`Are you sure you want to delete ${refNames.singular}: ${row.getValue(`${refNames.propName}`)}?`)
  ) {
    return;
  }
  return deleteClientRef.mutate(deleteRefWithId as any);
};

export default DeleteRow;
