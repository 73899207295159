import {useMemo} from 'react';
import {MRT_ColumnDef, MRT_Row} from 'material-react-table';
import {CircularProgress, MenuItem, Box, IconButton, Tooltip} from '@mui/material';
import {BaseRef} from './ClientBaseRef';
import ClientReferenceTable from '../../../Tables/ClientReferencesBaseTable';
import AuthService from '../../../../services/auth.service';
import ReferenceService, {RefNames} from '../../../../services/reference.service';
import ScaleService, {ClientScale} from '../../../../services/scale.service';
import GetEditTextFieldProps from '../../../Tables/TextFieldProps';
import DeleteRow from '../../../References/DeleteClientReference';
import {Delete} from '@mui/icons-material';

const refNames: RefNames = {singular: 'Location', plural: 'Locations', propName: 'Name'};

const ClientLocations = () => {
  const currentUser = AuthService.getCurrentUser();
  const clientId = currentUser?.clientId as number;
  const deleteClientRef = ReferenceService.useDeleteClientReference(clientId, refNames);

  const {isLoading: isLoadingScalesData, data: scalesData} = ScaleService.useGetClientScalesAll(clientId);

  const getCommonEditTextFieldProps = GetEditTextFieldProps;

  const columns = useMemo<MRT_ColumnDef<BaseRef>[]>(
    () => [
      {
        accessorKey: 'id', // sample.idx access nested data with dot notation
        header: 'Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 20,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Idx', // sample.idx access nested data with dot notation
        header: 'Idx',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 20,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'scale.id',
        header: 'Scale',
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: isLoadingScalesData ? (
            <MenuItem>
              <CircularProgress size={20} />
            </MenuItem>
          ) : scalesData ? (
            scalesData?.data.map((scale: ClientScale, index: any) => (
              <MenuItem
                key={index}
                value={scale.id}
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
              >
                {scale.scaleName}
              </MenuItem>
            ))
          ) : (
            []
          )
        },
        Cell: ({cell}) => (
          <Box>
            {scalesData?.data.map((scale: ClientScale, index: any) => {
              return scale.id === cell.getValue<number>() ? (
                <MenuItem key={index} value={scale.id}>
                  {scale.scaleName}
                </MenuItem>
              ) : null;
            })}
          </Box>
        ),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Name', //normal accessorKey
        header: 'Location name',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'licensePlate'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Barcode',
        header: 'Barcode',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Available',
        header: 'Status',
        Cell: ({cell}) => (
          <Box
            sx={(theme) => ({
              backgroundColor: cell.getValue<number>() === 1 ? theme.palette.success.dark : theme.palette.error.dark,

              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem'
            })}
          >
            {cell.getValue<number>() === 1 ? 'Active' : 'Inactive'}
          </Box>
        )
      },
      {
        accessorKey: 'Email',
        header: 'E-mail',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Street',
        header: 'Street',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'City',
        header: 'City',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'State',
        header: 'State',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'ZipCode',
        header: 'Zip code',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'PhoneNumber',
        header: 'Phone',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'GPSLong',
        header: 'GPSLong',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'GPSLat',
        header: 'GPSLat',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Radius',
        header: 'Radius',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Description1',
        header: 'Description 1',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Description2',
        header: 'Description 2',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Description3',
        header: 'Description 3',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Description4',
        header: 'Description 4',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'syncedToScale',
        header: 'Last sync',
        enableEditing: false,
        Cell: ({cell}) => {
          return <>{cell.getValue<Date>()}</>;
        },
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'deleteRow',
        header: 'Delete',
        columnDefType: 'display',
        Cell: ({row}: {row: MRT_Row<BaseRef>}) => (
          <>
            <Tooltip arrow placement="bottom" title="Delete">
              <IconButton color="error" onClick={() => DeleteRow({deleteClientRef, row, refNames})}>
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      }
    ],
    [getCommonEditTextFieldProps, isLoadingScalesData, scalesData, deleteClientRef]
  );

  return (
    <div>
      <ClientReferenceTable
        refNames={refNames}
        columns={columns}
        deleteClientRef
        // renderDetailPanel={({ row }: { row: any }) => (
        //     <Box
        //         sx={{
        //             display: 'grid',
        //             margin: 'auto',
        //             gridTemplateColumns: '1fr 1fr',
        //             width: '80%',
        //         }}
        //     >
        //         <Typography>State: {row.original.State}</Typography>
        //         <Typography>Zip Code: {row.original.ZipCode}</Typography>
        //         <Typography>City: {row.original.City}</Typography>
        //         <Typography>E-mail: {row.original.Email}</Typography>
        //         <Typography>Street: {row.original.Street}</Typography>
        //         <Typography>Phone: {row.original.PhoneNumber}</Typography>
        //         <Typography>Barcode: {row.original.Barcode}</Typography>
        //         <Typography>Latitude: {row.original.GPSLat}</Typography>
        //         <Typography>Longitude: {row.original.GPSLong}</Typography>
        //         <Typography>Radius: {row.original.Radius}</Typography>
        //         <Typography>Description1: {row.original.Description1}</Typography>
        //         <Typography>Description2: {row.original.Description2}</Typography>
        //         <Typography>Description3: {row.original.Description3}</Typography>
        //         <Typography>Description4: {row.original.Description4}</Typography>
        //     </Box>
        // )}
        // initialState={{
        //     columnVisibility: {
        //         State: false,
        //         ZipCode: false,
        //         City: false,
        //         Email: false,
        //         Street: false,
        //         PhoneNumber: false,
        //         GPSLong: false,
        //         GPSLat: false,
        //         Radius: false,
        //         Barcode: false,
        //         Description1: false,
        //         Description2: false,
        //         Description3: false,
        //         Description4: false,
        //     }
        // }}
      />
    </div>
  );
};

export default ClientLocations;
