import axios from 'axios';
import authHeader from './auth-header';
import {useQuery} from '@tanstack/react-query';
import Notification from '../Notifications/Notification';

const controller = new AbortController();

interface ScaleStatuses {
  key: number;
  name: string;
}

export const scaleStatuses: ScaleStatuses[] = [
  {key: 1, name: 'Active'},
  {key: 0, name: 'Inactive'}
];

export type ClientScale = {
  id: number;
  scaleName: string;
  location: string;
  lastServiceDate: Date;
  nextServiceDate: Date;
  machine: {
    id: number;
    scaleId: number;
    createdAt: Date;
    updatedAt: Date;
    LogTimeDate: Date;
  };
  // ipAddress?: string;
  // macAddress?: string;
  // imei?: string;
  // clientId: number;
  // brand?: string;
  // model?: string;
  // serialNumber?: string;
  // scaleInstallationDate?: Date;
  // modemActivationDate?: Date;
  // lanStatus: boolean;
  // scaleConnectionPackageId: number;
  // firstVerificationDate: Date;
  // firstVerificationPeriod: number;
  // verificationDocumentUrl: string;
  // timeZone: string;
  // dateTimeFormat: string;
  // updatedBy: number;
  createdAt: Date;
  updatedAt: Date;
  lastUpdatedAt: Date;
};

// const getClientScales = async (clientId: number) => {
//   const { data } = await axios.get(`/api/auth/v1/client/${clientId}/scales`, {
//     signal: controller.signal,
//     headers: authHeader(),
//   });
//   return data;
// };

function useGetClientScalesAll(clientId: number) {
  return useQuery({
    queryKey: ['scales', clientId],
    queryFn: async () => {
      return await axios.get(`/api/auth/v1/client/${clientId}/scales`, {
        signal: controller.signal,
        headers: authHeader()
      });
    },
    onError: (error: any) => {
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Notification({messageTitle: 'Error', message: resMessage, messageType: 'danger'});
    }
  });
}

const getClientScaleById = async (clientId: number, scaleId: number) => {
  const {data} = await axios.get(`/api/auth/v1/client/${clientId}/scales/${scaleId}`, {
    signal: controller.signal,
    headers: authHeader()
  });
  return data;
};

const ScaleService = {
  useGetClientScalesAll,
  getClientScaleById
};

export default ScaleService;
