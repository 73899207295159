import {Container} from '@mui/material';

export const ContactUs = () => {
  return (
    <Container>
      <div>
        <h1>This is the Contact Us page!</h1>
      </div>
    </Container>
  );
};
