import {useMemo} from 'react';
import {MRT_ColumnDef, MRT_Row} from 'material-react-table';
import AuthService from '../../../../services/auth.service';
import ReferenceService, {RefNames} from '../../../../services/reference.service';
import GetEditTextFieldProps from '../../../Tables/TextFieldProps';
import ScaleService, {ClientScale, scaleStatuses} from '../../../../services/scale.service';
import {BaseRef} from './ClientBaseRef';
import {CircularProgress, MenuItem, Box, IconButton, Tooltip} from '@mui/material';
import DeleteRow from '../../../References/DeleteClientReference';
import {Delete} from '@mui/icons-material';
import ClientReferenceTable from '../../../Tables/ClientReferencesBaseTable';

const refNames: RefNames = {singular: 'Customer', plural: 'Customers', propName: 'FirstName'};

const ClientCustomers = () => {
  const currentUser = AuthService.getCurrentUser();
  const clientId = currentUser?.clientId as number;
  const deleteClientRef = ReferenceService.useDeleteClientReference(clientId, refNames);

  const {isLoading: isLoadingScalesData, data: scalesData} = ScaleService.useGetClientScalesAll(clientId);

  const getCommonEditTextFieldProps = GetEditTextFieldProps;
  const columns = useMemo<MRT_ColumnDef<BaseRef>[]>(
    () => [
      {
        accessorKey: 'id', // sample.idx access nested data with dot notation
        header: 'Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 20,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Idx', // sample.idx access nested data with dot notation
        header: 'Idx',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 20,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'scale.id',
        header: 'Scale',
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: isLoadingScalesData ? (
            <MenuItem>
              <CircularProgress size={20} />
            </MenuItem>
          ) : scalesData ? (
            scalesData?.data.map((scale: ClientScale, index: any) => (
              <MenuItem key={index} value={scale.id}>
                {scale.scaleName}
              </MenuItem>
            ))
          ) : (
            []
          )
        },
        Cell: ({cell}) => (
          <Box>
            {scalesData?.data.map((scale: ClientScale, index: any) => {
              return scale.id === cell.getValue<number>() ? (
                <MenuItem
                  key={index}
                  value={scale.id}
                  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                  {scale.scaleName}
                </MenuItem>
              ) : null;
            })}
          </Box>
        ),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'FirstName', //normal accessorKey
        header: 'First Name',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'LastName',
        header: 'Last Name',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Available',
        header: 'Status',
        Cell: ({cell}) => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={(theme) => ({
              backgroundColor: cell.getValue<number>() === 1 ? theme.palette.success.dark : theme.palette.error.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem'
            })}
          >
            {cell.getValue<number>() === 1 ? 'Active' : 'Inactive'}
          </Box>
        ),
        muiTableBodyCellEditTextFieldProps: {
          select: true,
          children: scaleStatuses.map((status: any) => (
            <MenuItem key={status.key} value={status.key}>
              {status.name}
            </MenuItem>
          ))
        },
        size: 40,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Email',
        header: 'E-mail',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'Street',
        header: 'Street',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'City',
        header: 'City',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'State',
        header: 'State',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'ZipCode',
        header: 'Zip code',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'PhoneNumber',
        header: 'Phone',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string'
        }),
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'syncedToScale',
        header: 'Last sync',
        enableEditing: false,
        Cell: ({cell}) => {
          return <>{cell.getValue<Date>()}</>;
        },
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'deleteRow',
        header: 'Delete',
        columnDefType: 'display',
        Cell: ({row}: {row: MRT_Row<BaseRef>}) => (
          <>
            <Tooltip arrow placement="bottom" title="Delete">
              <IconButton color="error" onClick={() => DeleteRow({deleteClientRef, row, refNames})}>
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      }
    ],
    [getCommonEditTextFieldProps, isLoadingScalesData, scalesData, deleteClientRef]
  );

  return (
    <div>
      <ClientReferenceTable
        refNames={refNames}
        columns={columns}
        // renderDetailPanel={({ row }: { row: any }) => (
        //   <Box
        //     sx={{
        //       display: "grid",
        //       margin: "auto",
        //       gridTemplateColumns: "1fr 1fr",
        //       width: "80%",
        //     }}
        //   >
        //     <Typography>State: {row.original.State}</Typography>
        //     <Typography>Zip Code: {row.original.ZipCode}</Typography>
        //     <Typography>City: {row.original.City}</Typography>
        //     <Typography>E-mail: {row.original.Email}</Typography>
        //     <Typography>Street: {row.original.Street}</Typography>
        //     <Typography>Phone: {row.original.PhoneNumber}</Typography>
        //   </Box>
        // )}
        deleteClientRef
        // initialState={{
        //   columnVisibility: {
        //     State: false,
        //     ZipCode: false,
        //     City: false,
        //     Email: false,
        //     Street: false,
        //     PhoneNumber: false,
        //   },
        // }}
      />
    </div>
  );
};

export default ClientCustomers;
