import axios from 'axios';
import AuthHeader from './auth-header';
import AuthService from '../services/auth.service';
const controller = new AbortController();
const API_URL = process.env.REACT_APP_BASE_URL + '/api/auth/v1';

const getPublicContent = () => {
  return axios.get(API_URL + 'all', {
    signal: controller.signal
  });
};

const getSuperuserBoard = () => {
  return axios.get(API_URL + 'superuser', {
    signal: controller.signal,
    headers: AuthHeader.authHeader()
  });
};

const getDealerBoard = () => {
  return axios.get(API_URL + 'dealer', {
    signal: controller.signal,
    headers: AuthHeader.authHeader()
  });
};

const getClientScales = async (clientId) => {
  try {
    var data = await axios.get(API_URL + '/dashboards/client/' + clientId + '/scales', {
      signal: controller.signal,
      headers: AuthHeader.authHeader()
    });
  } catch (error) {
    throw new Error(error.message);
  }
  return data;
};

const getRecordsList = async (clientId) => {
  try {
    var data = await axios.get(API_URL + '/records/client/' + clientId + `/records`, {
      signal: controller.signal,
      headers: AuthHeader.authHeader()
    });
  } catch (error) {
    throw new Error(error.message);
  }
  return data;
};

const getReferenceList = async (reference, clientId) => {
  try {
    var data = await axios.get(API_URL + '/references/client/' + clientId + `/${reference}`, {
      signal: controller.signal,
      headers: AuthHeader.authHeader()
    });
  } catch (error) {
    throw new Error(error.message);
  }
  return data;
};

const addReference = async (reference, clientId, body) => {
  try {
    var data = await axios.post(API_URL + '/references/client/' + clientId + `/${reference}`, body, {
      signal: controller.signal,
      headers: AuthHeader.authHeader()
    });
  } catch (error) {
    throw new Error(error.message);
  }
  return data;
};

const updateReference = async (reference, clientId, body) => {
  const referenceId = body.id;
  try {
    var data = await axios.put(API_URL + '/references/client/' + clientId + `/${reference}/` + referenceId, body, {
      signal: controller.signal,
      headers: AuthHeader.authHeader()
    });
  } catch (error) {
    throw new Error(error.message);
  }
  return data;
};

const deleteReference = async (reference, clientId, referenceId) => {
  try {
    var data = await axios.delete(API_URL + '/references/client/' + clientId + `/${reference}/` + referenceId, {
      signal: controller.signal,
      headers: AuthHeader.authHeader()
    });
  } catch (error) {
    throw new Error(error.message);
  }
  return data;
};

const getClientProducts = async (clientId, startDate, endDate) => {
  const params = {
    startDate: startDate,
    endDate: endDate
  };

  try {
    var productList = await axios.get(API_URL + '/dashboards/client/' + clientId + '/products', {
      signal: controller.signal,
      headers: AuthHeader.authHeader(),
      params: params
    });
  } catch (error) {
    throw new Error(error.message);
  }

  return productList;
};

const getUserInfo = async (token, userId) => {
  try {
    var userData = await axios.get(API_URL + 'user/' + userId, {
      signal: controller.signal,
      headers: AuthHeader.authHeader()
    });
  } catch (error) {
    AuthService.logout();
    return error;
  }
  return userData;
};

const UserService = {
  getPublicContent,
  getSuperuserBoard,
  getDealerBoard,
  getClientScales,
  getClientProducts,
  getUserInfo,
  getReferenceList,
  addReference,
  updateReference,
  deleteReference,
  getRecordsList
};

export default UserService;
